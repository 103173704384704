import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './CSS/resset_form.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import FoodsProvider from './context/FoodsContext/FoodsProvider';
import DrinksProvider from './context/DrinksContext/DrinksProvider';
import ProfileProvider from './context/ProfileContext/ProfileProvider';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <DrinksProvider>
    <FoodsProvider>
      <ProfileProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ProfileProvider>
    </FoodsProvider>
  </DrinksProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
